:root {

    --code-width: 40%;

}

.container {

    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: var(--background-secondary);
    padding: 0px 0px 0px 300px;
    box-sizing: border-box;
    overflow: hidden;

    animation: onLoad .25s 1  forwards;
}

.container .profile {

    position: fixed;
    right: 30px;
    top: 30px;
    transform: translateY(-30%);

}

.container .content {

    display: flex;
    overflow: auto;
    height: 100vh;
    padding: 60px 100px;
    flex-direction: column;
    box-sizing: border-box;
    transition: padding .25s;
    position: absolute;
    top: 0px;
    width: calc(100% - 300px);

}

.container .content .projects {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
    margin-top: 40px;
}

.container .content .projects .project {
    width: 380px;
    height: 155px;
    min-width: 300px;
    display: flex;
    background-color: var(--background-primary);
    box-shadow: var(--box-shadow-primary);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 24px 20px;
    flex-direction: column;
    transform-origin: center center;
    position: relative;
}

.container .content .projects .project.create {
    justify-content: center;
    height: 110px;
}

.container .content .projects .project.create .info {
    align-items: center;
}

.container .content .projects .project.create .info .description {
    margin-bottom: 0px;
}

.container .content .projects .project.invite .invite {
    display: flex;
    column-gap: 8px;
    margin-top: 6px;
    flex-direction: row-reverse;
}

.container .content .projects .project.invite .invite .button {
    font-size: 13px;
    flex-basis: 0;
    flex: 1;
}

.container .content .projects .project.invite .invite .button .wrapper {
    padding: 10px 20px;
}

.container .content .projects .project .skeleton {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}

.container .content .projects .project:not(.invite):hover {
    cursor: pointer;
    background-color: var(--project-background-hover);
}

.container .content .projects .project .info {
    display: flex;
    column-gap: 15px;
}

.container .content .projects .project .icon {
    width: 50px;
    height: 50px;
    max-height: 50px;
    position: relative;
    background-color: var(--polyfill-primary);
    border-radius: 50%;
}

.container .content .projects .project .icon img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
    pointer-events: none;
    user-select: none;
    border-radius: 50%;
}

.container .content .projects .project .icon svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 75%;
    pointer-events: none;
    user-select: none;
    stroke: var(--text-secondary);
    stroke-width: 12.5%;
    fill: transparent;
    opacity: .25;
}

.container .content .projects .project .icon svg.create {
    width: 45%;
    stroke-width: 1.5;
}

.container .content .projects .project .details {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.container .content .projects .project:not(.invite) .details {
    pointer-events: none;
    user-select: none;
}


.container .content .projects .project .title,
.container .content .projects .project .name {

    font-weight: 600;
    font-size: 17px;
    margin-bottom: 5px;
    color: var(--text-primary);

}

.container .content .projects .project .description,
.container .content .projects .project .owner {

    font-weight: 400;
    font-size: 14px;
    color: var(--text-secondary);
    margin-bottom: 5px;

}

.container .content .projects .project .description {
    line-height: 1.6em;
    font-weight: 500;
}

.container .content .projects .project .created-on {

    font-weight: 400;
    font-size: 14px;
    color: var(--text-secondary)

}

.container .content .projects .project .members {

    font-weight: 400;
    font-size: 14px;
    color: var(--text-secondary);
    margin-bottom: 5px;

}

.container .content .projects .project .contributors {
    display: flex;
}

.container .content .projects .project .contributors .contributor {
    width: 24px;
    height: 36px;
    position: relative;
}

.container .content .projects .project .contributors .contributor::before {
    content: '';
    width: 36px;
    height: 36px;
    position: relative;
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: var(--polyfill-primary);
    border-radius: 50%;
    z-index: 1;
}

.container .content .projects .project .contributors .contributor img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 2px solid var(--background-primary);
    box-sizing: border-box;
    pointer-events: none;
    user-select: none;
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: var(--polyfill-primary);
    z-index: 2;
}

.container .content .projects .project:hover .contributors .contributor img {
    border: 2px solid var(--project-background-hover);
}

.container .content.transition-enter {
    opacity: 0;
    transform: translateY(30px);
}

.container .content.transition-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: transform .3s, opacity .3s;
    transition-delay: .05s;
    pointer-events: none;
}

.container .content.transition-exit {
    opacity: 1;
    transform: translateY(0px);
}

.container .content.transition-exit-active {
    opacity: 0;
    transform: translateY(-30px);
    transition: transform .15s, opacity .15s;
    pointer-events: none;
}

.section {

    background-color: var(--background-primary);
    max-width: 1000px;
    margin-top: 40px;
    padding: 0px 0px;
    box-sizing: border-box;
    box-shadow: var(--box-shadow-primary);
    border-radius: 10px;

}

.label {

    font-size: 16px;
    color: var(--text-secondary);
    font-weight: 500;
    margin-bottom: -40px;
    margin-top: 40px;

}

.container .content .projects .empty {

}

@keyframes onLoad {
    0% {
        opacity: 0;
        transform: scale(1.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}