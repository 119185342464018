.button {
    
    background-color: var(--button-primary);
    color: var(--button-primary-text);
    border: 0px solid transparent;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color .1s;

}

.button.disabled {
    
    cursor: not-allowed;
    filter: brightness(.95);

}

.button.loading {

    color: transparent!important;

}

.button:not(.disabled):hover {

    background-color: var(--button-primary-hover);

}

.button:not(.disabled):active {

    background-color: var(--button-primary-active);

}

.button.secondary {

    background-color: var(--button-secondary);
    color: var(--button-secondary-text);
    font-weight: 600;

}

.button.secondary:not(.disabled):hover {

    background-color: var(--button-secondary-hover);

}

.button.secondary:not(.disabled):active {

    background-color: var(--button-secondary-active);

}

.button .contents {

    display: flex;
    padding: 10px 50px;
    box-sizing: border-box;
    position: relative;
    white-space: nowrap;
    text-align: center;
    justify-content: center;
    align-items: center;

}

.button .contents .loading {

    position: absolute;
    left: 50%;
    top: 50%;
    width: 16px;
    height: 16px;
    transform: translate(-50%, -50%);

}

.button .contents .label {

    text-align: center;

}

.button .contents .loading svg {
    
    stroke: var(--button-primary-text);

}

.button.secondary .contents .loading svg {
    
    stroke: var(--button-secondary-text);

}

.button.danger {

    background-color: var(--button-danger);
    color: var(--button-danger-text);
    font-weight: 600;

}

.button.danger:not(.disabled):hover {

    background-color: var(--button-danger-hover);

}

.button.danger:not(.disabled):active {

    background-color: var(--button-danger-active);

}


