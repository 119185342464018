.member {
    font-weight: 800;
}

.button-tray {

    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    background-color: var(--background-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 40px 40px 40px;
    box-sizing: border-box;
    column-gap: 14px;
    border-radius: 0px 0px 5px 5px;

}

.button {

    width: 100%;

}

.input {
    margin-bottom: 0px;
}