.container {

    position: relative;
    height: 55px;
    width: calc(100% - 40px);
    background-color: var(--background-secondary);
    border-radius: 10px;
    margin: 0px 20px;
    box-sizing: border-box;
    outline: 2px solid var(--border-primary);

}

.container:hover,
.container.focus {

    outline: 2px solid var(--tint)

}

.loading .details > svg {
    display: none;
}

.icon .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.loading .icon {
    background-color: transparent;
    border: 0px solid transparent;
}

.options {
    position: absolute;
    top: calc(100% + 10px);
    width: 100%;
    background-color: var(--background-secondary);
    border: 2px solid var(--border-primary);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px 2px;
    box-sizing: border-box;
    box-shadow: 0px 0px 3px var(--box-shadow-primary);
    display: none;
    cursor: initial;
    z-index: 1000;
}

.focus .options {
    display: block;
}

.option {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 42px;
    cursor: pointer;
}

.option.create {

    margin: 15px 0px 0px 0px;

}

.option.create::after {

    content: '';
    width: calc(100% - 20px);
    height: 1px;
    position: absolute;
    left: 10px;
    top: -7.5px;
    transform: translateY(-50%);
    pointer-events: none;
    background-color: rgba(0,0,0,0.1);

}


.option:hover::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: calc(100% - 10px);
    height: 100%;
    background-color: var(--server-selector-item-hover);
    border-radius: 10px;
}

.option.selected::before {
    display: none;
}

.option .details {
    width: calc(100% - 30px);
    display: flex;
    position: relative;
    user-select: none;
    pointer-events: none;
}

.option .details .icon {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
}

.option .details .label {
    padding: 0px 0px 0px 34px;
    box-sizing: border-box;
    color: var(--text-secondary);
    font-weight: 500;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.option .details svg {
    stroke: var(--text-secondary);
    stroke-width: 1px;
    fill: transparent;
    height: 16px;
    position: absolute;
    right: 0px;
    top: 50%;
    transition: transform .15s;
    transform: translateY(-50%) rotateZ(90deg);
}

.focus .option .details svg {
    transform: translateY(-50%) rotateZ(-90deg);
}

.option.selected {
    height: 100%;
    position: relative;
}

.icon {

    width: 24px;
    height: 24px;
    border-radius: 50%;
    box-sizing: border-box;
    border: var(--avatar-border-width) solid var(--border-primary);
    justify-content: center;
    position: relative;
    background-color: var(--polyfill-primary);
    font-size: 0px;

}

.icon::before {

    content: attr(data-label);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    font-size: 10px;
    color: var(--text-secondary);
    font-weight: 600;
    margin: 0px 0px 0px 0px;

}

.icon img {

    width: calc(100% + var(--avatar-border-width) * 2);
    height: calc(100% + var(--avatar-border-width) * 2);
    left: calc(var(--avatar-border-width) * -1);
    top: calc(var(--avatar-border-width) * -1);
    z-index: 10;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    transition: opacity .25s;

}

.icon svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 75%;
    pointer-events: none;
    user-select: none;
    stroke: var(--text-secondary);
    stroke-width: 12.5%;
    fill: transparent;
    opacity: .25;
    background-color: transparent;
}

.icon svg.project-icon {
    transform: translate(-50%,-50%)!important;
}

.icon svg.plus {
    transform: translate(-50%,-50%)!important;
    width: 50%;
    stroke: var(--text-secondary);
    opacity: .5;
}