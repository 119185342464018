.container {
    transition: background-color var(--animation-time);
    position: relative;
    display: inline-block;
    width: fit-content;
    --animation-time: .25s;
}

.container .skeleton {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: hidden; 
    border-radius: 7px;
    transition: background-color var(--animation-time);
    pointer-events: none;
    user-select: none;
}

.container .contentWrapper {
    position: relative;
}

.container .content {
    transition: opacity var(--animation-time);
    opacity: 1;
}

.container.loading .content {
    opacity: 0;
}

.container.loading * {
    pointer-events: none;
}

.container.loading .skeleton {
    background-color: var(--polyfill-primary);
}

.container.loading .skeleton::after {
    content: '';
    background: linear-gradient(90deg,
            rgba(255, 255, 255, 0) 0,
            rgba(255, 255, 255, 0.2) 20%,
            rgba(255, 255, 255, 0.5) 60%,
            rgba(255, 255, 255, 0));
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    transform: translateX(-100%);
    animation: shimmer 2s infinite;
    transition: opacity var(--animation-time);
    opacity: 1;
    pointer-events: none;
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}