.actions {
    width: 100%;
    height: 50px;
    display: flex;
    margin-top: 40px;
    justify-content: stretch;
    max-width: 1000px;
}

.actions .invite {
    width: 120px;
    margin-left: 10px;
}

.search {
    height: 50px;
    border-radius: 10px;
    background-color: var(--background-primary);
    box-shadow: var(--box-shadow-primary);
    display: flex;
    flex: 1;
    position: relative;
}

.search .icon {
    height: 50px;
    width: 55px;
    position: relative;
}

.search .icon svg {
    height: 16px;
    width: 16px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    fill: var(--text-tertiary);
}

.search .input {
    width: 100%;
    outline: none;
    border: 0px solid transparent;
    background-color: transparent;
    box-sizing: border-box;
    padding: 0px 0px 0px 0px;
    font-size: 15px;
}

.search .loader {

    width: 20px;
    height: 20px;
    stroke: var(--text-secondary);
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transform: opacity .25s;

}

.search.loading .loader {
    opacity: 1;
}

.wrapper {
    width: 100%;
    background-color: var(--background-primary);
    box-shadow: var(--box-shadow-primary);
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px 0px;
    max-width: 1000px;
}

.wrapper .labels {
    display: flex;
    padding: 0px 60px 0px 100px;
    box-sizing: border-box;
    justify-content: stretch;
    margin: 12px 0px 0px 0px;
}

.wrapper .labels .label {
    flex: 1;
    color: var(--text-tertiary);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
}

.wrapper .members {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.wrapper .members .member {
    display: flex;
    position: relative;
}

.wrapper .members .member:not(:last-child)::after {
    content: '';
    width: calc(100% - 60px);
    left: 30px;
    height: 1px;
    top: 100%;
    background-color: var(--border-primary);
    opacity: .5;
    position: absolute;
}

.wrapper .members .member div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    font-weight: 500;
    font-size: 16px;
    color: var(--text-primary)
}

.wrapper .members .member .avatar {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.wrapper .members .member .skeleton {
    width: 100%;
    height: 100%;
    min-width: 0%;
    max-width: 100%;
}

.wrapper .members .member .avatar::before {
    content: '';
    width: 36px;
    height: 36px;
    background-color: rgba(0,0,0,0.1);
    display: block;
    position: absolute;
    border-radius: 50%;
}

.wrapper .members .member .avatar .skeleton,
.wrapper .members .member .avatar img {
    width: 36px;
    height: 36px;
    max-width: 36px;
    border-radius: 50%!important;
    vertical-align: middle;
    display: inline-block;
    opacity: 0;
    transition: opacity .25s;
    position: relative;
    overflow: hidden;
}

.wrapper .members .member .avatar .skeleton {
    opacity: 1;
}

.wrapper .members .member .avatar .skeleton .wrapper {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
}

.wrapper .members .member .avatar .loader {
    width: 16px;
    height: 16px;
    stroke: rgba(0,0,0,0.2);
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
    transform: opacity .25s;
    position: absolute;
}

.wrapper .members .member .avatar.loaded .loader {
    opacity: 0;
}

.wrapper .members .member .avatar.loaded img {
    opacity: 1;
}

.wrapper .members .member .remove {
    margin-right: 10px;
    max-width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50%;
    cursor: pointer;
}


.wrapper .members .member .remove svg {

    max-width: 14px;
    height: 14px;
    stroke: var(--text-secondary);
    z-index: 3;
    pointer-events: none;

}

.wrapper .members .member .remove::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    background-color: transparent;
    width: 40px;
    height: 40px
}

.wrapper .members .member .remove:hover::before {
    background-color: rgba(0,0,0,0.05)
}

.wrapper .members .member .remove:active::before {
    background-color: rgba(0,0,0,0.1)
}

.wrapper .members .member .name,
.wrapper .members .member .role,
.wrapper .members .member .date {
    padding: 0px 30px 0px 0px;
    box-sizing: border-box;
}