.transition-enter {
    transform: translate(-50%,-50%) scale(0)!important;
}

.transition-enter-active {
    transform: translate(-50%,-50%) scale(1)!important;
    transition: transform .2s;
}

.transition-exit {
    transform: translate(-50%,-50%) scale(1)!important;
}

.transition-exit-active {
    transform: translate(-50%,-50%) scale(0)!important;
    transition: transform .2s;
}

.dialog-wrapper {

    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    transition: background-color .2s, opacity .2s;
    background-color: rgba(0,0,0,0);
    pointer-events: none;
    opacity: 0;
    z-index: 1000;

}

.dialog-wrapper.visible {

    pointer-events: initial;
    opacity: 1;
    background-color: rgba(0,0,0,0.8);
    transition: background-color .2s, opacity 0s;

}

.dialog-wrapper .dialog {

    position: absolute;
    left: 50%;
    top: 50%;
    background-color: var(--background-primary);
    transform: translate(-50%, -50%);
    width: 450px;
    height: auto;
    border-radius: 5px;
    padding: 0px 40px 100px 40px;
    box-sizing: border-box;
    color: var(--text-primary);
    text-align: center;

}

.dialog-wrapper .dialog .button-tray {

    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    background-color: var(--background-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 40px 40px 40px;
    box-sizing: border-box;
    column-gap: 14px;
    border-radius: 0px 0px 5px 5px;

}

.dialog-wrapper .dialog .button-tray .button {

    width: 100%;

}


.dialog-wrapper .dialog h2 {

    margin-top: 38px;
    font-size: 26px;

}

.dialog-wrapper .dialog .subtitle {

    margin-top: -12px;
    margin-bottom: 0px;
    color: var(--text-secondary);
    font-weight: 600;
    line-height: 26px;

}

.dialog-wrapper .dialog .disclaimer {
    margin-top: -12px;
    margin-bottom: 12px;
    color: var(--text-secondary);
    font-weight: 600;
    line-height: 26px;
}

.dialog-wrapper .dialog .message {

    margin-top: 24px;
    line-height: 24px;

}

.dialog-wrapper .dialog .input {

    margin-bottom: 0px;
    margin-top: 18px;

}

.dialog-wrapper .dialog .content {

    padding: 20px 0px;

}