.item {

    padding: 34px 30px;
    box-sizing: border-box;
    position: relative;

}

.item::after {

    content: '';
    width: calc(100% - 60px);
    height: 1.5px;
    background-color: var(--border-primary);
    position: absolute;
    left: 30px;
    bottom: 0px;
    transform: translateY(50%);

}

.wrapper:last-child .item::after {

    display: none;

}

.label {

    font-size: 11px;
    font-weight: 600;
    margin-bottom: 7px;
    color: var(--text-secondary);
    text-transform: uppercase;

}

.value {

    font-size: 16px;
    font-weight: 400;
    color: var(--text-primary);

}

.item {
    display: flex;
    flex-direction: column;
}

.item .button {
    display: inline-block;
    width: fit-content;
}

.item .button.copy {
    margin-top: 8px;
    width: 120px;
}

.item .button.reset {
    margin-top: 0px;
}

.item .button.copied {
    background-color: var(--button-success);
    color: var(--button-success-text);
}

.item .button.copied:hover {
    background-color: var(--button-success-hover);
}

.item .button.copied:active {
    background-color: var(--button-success-active);
}

.item .button .buttonContent {
    padding: 8px 30px;
    font-size: 14px;
}

.sectionTitle {
    margin: 50px 0px -20px 0px;
    font-weight: 600;
}

.sectionTitle.subtitled {
    margin: 50px 0px 0px 0px;
}

.headingSubtitle {
    margin-bottom: -16px;
}

.sectionTitle:first-of-type {
    margin: 30px 0px -20px 0px;
}

.actions {
    width: 100%;
    height: 50px;
    display: flex;
    margin-top: 40px;
    justify-content: stretch;
    max-width: 1000px;
}

.actions .add {
    width: 120px;
    margin-left: 10px;
}

.input-wrapper {
    height: 50px;
    border-radius: 10px;
    background-color: var(--background-primary);
    box-shadow: var(--box-shadow-primary);
    display: flex;
    flex: 1;
    position: relative;
}

.input-wrapper.error {
    box-shadow: none;
    outline: 2px solid var(--input-border-error);
    box-sizing: border-box;
}

.input-wrapper .icon {
    height: 50px;
    width: 55px;
    position: relative;
}

.input-wrapper .input {
    width: 100%;
    outline: none;
    border: 0px solid transparent;
    background-color: transparent;
    box-sizing: border-box;
    padding: 0px 16px 0px 16px;
    font-size: 15px;
}

.search .loader {

    width: 20px;
    height: 20px;
    stroke: var(--text-secondary);
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transform: opacity .25s;

}

.search.loading .loader {
    opacity: 1;
}

.wrapper {
    width: 100%;
    background-color: var(--background-primary);
    box-shadow: var(--box-shadow-primary);
    border-radius: 10px;
    margin-top: 10px;
    padding: 0px 0px;
    max-width: 1000px;
}

.wrapper .labels {
    display: flex;
    padding: 0px 60px 0px 100px;
    box-sizing: border-box;
    justify-content: stretch;
    margin: 12px 0px 0px 0px;
}

.wrapper .labels .label {
    flex: 1;
    color: var(--text-tertiary);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
}

.wrapper .redirects {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.wrapper .redirects .redirect {
    display: flex;
    position: relative;
}

.wrapper .redirects .redirect:not(:last-child)::after {
    content: '';
    width: calc(100% - 60px);
    left: 30px;
    height: 1px;
    top: 100%;
    background-color: var(--border-primary);
    opacity: .5;
    position: absolute;
}

.wrapper .redirects .redirect .url {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    font-weight: 500;
    font-size: 16px;
    color: var(--text-primary);
    padding: 0px 18px 0px 18px;
    box-sizing: border-box;
}

.wrapper .redirects .redirect .remove {
    margin-right: 10px;
    max-width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50%;
    cursor: pointer;
    flex: 1;
}


.wrapper .redirects .redirect .remove svg {

    max-width: 14px;
    height: 14px;
    stroke: var(--text-secondary);
    z-index: 3;
    pointer-events: none;

}

.wrapper .redirects .redirect .remove .loader {

    width: 14px;
    height: 14px;
    stroke: var(--text-secondary);
    z-index: 3;
    pointer-events: none;

}

.wrapper .redirects .redirect .remove::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    background-color: transparent;
    width: 40px;
    height: 40px
}

.wrapper .redirects .redirect .remove:hover::before {
    background-color: rgba(0,0,0,0.05)
}

.wrapper .redirects .redirect .remove:active::before {
    background-color: rgba(0,0,0,0.1)
}