.header-1 {

    font-weight: 600;
    letter-spacing: .5px;

}

.header-2 {

    font-weight: 600;
    letter-spacing: .1px;
    font-size: 26px;

}


.tm-16 {
    margin-top: 16px;
}

.tm-32 {
    margin-top: 32px;
}

.tm-48 {
    margin-top: 48px;
}

.tm-64 {
    margin-top: 64px;
}

.no-bottom-margin {

    margin-bottom: 0px;

}