.item {

    padding: 34px 30px;
    box-sizing: border-box;
    position: relative;

}

.item::after {

    content: '';
    width: calc(100% - 60px);
    height: 1.5px;
    background-color: var(--border-primary);
    position: absolute;
    left: 30px;
    bottom: 0px;
    transform: translateY(50%);

}

.wrapper:last-child .item::after {

    display: none;

}

.label {

    font-size: 11px;
    font-weight: 600;
    margin-bottom: 7px;
    color: var(--text-secondary);
    text-transform: uppercase;
    user-select: none;

}

.value {

    font-size: 16px;
    font-weight: 400;
    color: var(--text-primary);
    user-select: none;

}

.icon-container {
    padding: 34px 30px;
    box-sizing: border-box;
    position: relative;
    width: 240px;
    height: 240px;
    margin: 34px 20px 0px 30px;
    border-radius: 10px;
    border: 2px solid var(--input-border);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-container .icon {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    color: transparent;
}

.icon-container div.icon {
    border-radius: 50%;
}

.icon-container div.icon .icon {
    background-color: rgba(0,0,0,0.1);
    cursor: pointer;
}

.icon-container div.icon svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 75%;
    pointer-events: none;
    user-select: none;
    stroke: var(--text-secondary);
    stroke-width: 12.5%;
    fill: transparent;
    opacity: .25;
    background-color: transparent;
}

.heading {
    display: flex;
}

.heading .item {
    padding: 0px 30px 17px 15px;
}

.heading .item:last-child {
    padding-bottom: 30px;
}

.heading .item::after {
    display: none;
}

.heading .item .skeleton {
    border-radius: 7px;
    width: 100%;
    display: flex;
    justify-content: stretch;
}

.heading .item .skeleton .content {
    width: 100%;
}

.heading .details {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 34px 0px 0px 0px;
}

.heading .details .input {
    margin-bottom: 0px;
    width: 100%;
    flex: 1;
    display: block;
}

.body {
    display: flex;
    flex-direction: column;
}

.body .item {
    display: flex;
    flex-direction: column;
}

.body .item .button {
    display: inline-block;
    margin-top: 10px;
    width: 100px;
}

.body .item .button.copy {
    background-color: var(--button-success);
    color: var(--button-success-text);
}

.body .item .button.copy:hover {
    background-color: var(--button-success-hover);
}

.body .item .button.copy:active {
    background-color: var(--button-success-active);
}

.body .item .button .buttonContent {
    padding: 8px 30px;
    font-size: 14px;
}

.details .button-tray {
    padding: 0px 30px 17px 15px;
    box-sizing: border-box;
    column-gap: 10px;
    display: flex;
    flex-direction: row-reverse;
}