:root {
    --image-cropper-checkered-even: #eee;
    --image-cropper-checkered-odd: #fff;

    --overlay: #fff;
    --overlay-shadow: 0 0 0 9999px rgb(0,0,0,0.6);
}

.container {
    display: block;
}

.cropper {
    width: 100%;
    display: block;
    position: relative;
    box-sizing: border-box;
    background-color: var(--image-cropper-background);
    margin-bottom: 30px;
    height: 350px;
    border-radius: 10px;
    overflow: hidden;
    cursor: grab;
    user-select: none;
    background-position: 0px 0px, 20px 20px;
    background-size: 40px 40px;
    background-blend-mode: normal, difference, normal;
    background-image: 
        linear-gradient(45deg, var(--image-cropper-checkered-even) 25%, transparent 25%, transparent 75%, var(--image-cropper-checkered-even) 75%, var(--image-cropper-checkered-even) 100%),
        linear-gradient(45deg, var(--image-cropper-checkered-even) 25%, var(--image-cropper-checkered-odd) 25%, var(--image-cropper-checkered-odd) 75%, var(--image-cropper-checkered-even) 75%, var(--image-cropper-checkered-even) 100%);
}

.cropper img {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    user-select: none;
    pointer-events: none;
}

.overlay-wrapper {
    height: 350px;
    width: 350px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    user-select: none;
}

.wrapper {
    height: 350px;
    width: 350px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    user-select: none;
}

.cropper .overlay {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 5px solid var(--overlay);
    box-sizing: border-box;
    box-shadow: var(--overlay-shadow);
    overflow: hidden;
    user-select: none;
}

.slider {
    margin-bottom: 30px;
}