.container {

    position: relative;
    min-width: 0px;
    width: 100%;
    overflow: visible;
    margin: 0px 0px 20px 0px;
    flex: 1;

}

.container .wrapper {

    width: 100%;
    position: relative;
    overflow: visible;

}

.container .wrapper textarea {

    width: 100%;
    border: 0px solid transparent;
    outline: none;
    border: 2px solid var(--input-border);
    border-radius: 8px;
    padding: 16px 18px;
    font-size: 16px;
    box-sizing: border-box;
    transition: border .2s;
    min-height: 126px;
    resize: none;

}

.container .wrapper textarea {
    -ms-overflow-style: none;
    scrollbar-width: none;
    line-height: 1.5em;
}
.container .wrapper textarea ::-webkit-scrollbar { 
    display: none; 
}

.container .wrapper .placeholder {

    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%) translateX(-5px);
    font-size: 16px;
    pointer-events: none;
    color: var(--input-placeholder);
    transition: top .2s, font-size .2s, color .2s;
    padding: 0px 5px;
    background-color: var(--background-primary);
    user-select: none;

}

.container.focused .wrapper .placeholder,
.container.not-empty .wrapper .placeholder {

    top: 0%;
    font-size: 12px;

}

.container.focused .wrapper .placeholder {

    color: var(--input-focused);

}

.container.focused .wrapper textarea {
 
    border: 2px solid var(--input-focused);

}